import React, { useEffect } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RoundedSearchTextField from "./RoundedSearchTextField";
import infoIcon from "img/infoIcon.svg";
import variables from "styles/base/_universal.scss";

const MultiSelectList = ({
  data,
  open,
  enableSearch = true,
  handleSelection,
  selected,
  height = "100%",
  searchResults,
  inlineButton = false,
  handleSearch,
  inputValue = "",
  placeholder,
}) => {
  const isSelected = (element) => {
    return selected?.findIndex((el) => element.id === el.id) > -1;
  };
  return (
    <div style={{ paddingTop: "12px" }}>
      {enableSearch && (
        <div style={{ margin: "0px 16px" }}>
          <RoundedSearchTextField
            onTextChange={searchResults}
            handleSearch={(type) => {
              if (inlineButton) {
                handleSearch(type);
              }
            }}
            inlineButton={inlineButton}
            inputValue={inputValue}
            placeholder={placeholder}
          />
        </div>
      )}
      <div
        className="multi-select-list customScrollBar"
        style={{ height: height }}
      >
        {data?.length === 0 && (
          <div
            style={{
              margin: "14px 16px",
              fontSize: variables.fontSmall,
              color: variables.grayFilterColor,
              display: "flex",
            }}
          >
            <img
              src={infoIcon}
              alt="Info"
              style={{ marginRight: "6px", marginBottom: "30px" }}
            />
            <p> No Result Found, Try Using Different Keywords</p>
          </div>
        )}

        <MenuList autoFocusItem={open} id="menu-list-grow">
          <div
          // className="multi-select-list customScrollBar"
          // style={{ height: height }}
          >
            {data
              ?.slice() // Create a shallow copy to avoid mutating the original array
              .sort((a, b) => a.displayName.localeCompare(b.displayName)) // Sort alphabetically
              .map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelection(!isSelected(option), option);
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={option.id}
                        className="checkBox"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(ev) => {
                          handleSelection(ev.target.checked, option);
                        }}
                        name={option.displayName}
                        color="primary"
                        size="small"
                        checked={isSelected(option)}
                      />
                    }
                    // label={option.displayName}
                  />
                  <span>{option.displayName}</span>
                </MenuItem>
              ))}
          </div>
        </MenuList>
      </div>
    </div>
  );
};

export default MultiSelectList;
