import React, { useState } from "react";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import styles from "styles/components/businessRules/businesrules.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { routes } from "Uitls/Util";

const BusinessrulesFooter = (props) => {
  const { generateBusinessRulesObj, hasEmptyRules } = props;
  const history = useHistory();

  return (
    <div className={`row align-items-center ${styles.moduleFooterRow}`}>
      <div className="col-md-6"></div>
      <div className="col-md-6 text-right">
        <LinkButton
          title="CANCEL"
          handleClick={() => {
            history.push({
              pathname: routes.dashboard,
              state: {
                selectedTab: 1,
              },
            });
          }}
          // disabled={!selectedData || selectedData.length === 0}
        />
        <PrimaryButton
          title="NEXT"
          handleClick={() => {
            generateBusinessRulesObj();
            //  goBack();
          }}
          minWidth={110}
          marginLeft={20}
          disabled={hasEmptyRules}
        />
      </div>
    </div>
  );
};

export default BusinessrulesFooter;
