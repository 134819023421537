import React, { useState, useContext, useEffect } from "react";
import FooterButtons from "components/FooterButtons";
import LinkButton from "components/LinkButton";
import PrimaryButton from "components/PrimaryButton";
import AuthorInfo from "components/AuthorInfo";
import RightPane from "./RightPane";
import CircularProgressBar from "Uitls/CircularProgressBar";
import { useKeycloak } from "@react-keycloak/web";
import {
  createModule,
  updateModule,
  getLanguageForCountry,
  getSubTopics,
  cloneModule,
  getSegmentList,
  getDraftElementsList,
} from "services/apis";
import { showError } from "Uitls/Util";
import styled from "styled-components";
import variables from "styles/base/_universal.scss";
import { ModuleContext } from "contexts/ModuleContext";
import {
  getIndicationDataForSave,
  getSegmentDataForSave,
  getDraftElementsDataForSave,
} from "Uitls/Util";
declare function cwr(operation: string, payload: any): void;
const MetaDataInformation = ({
  closeMetaDataScreen,
  gotoNextPage,
  editMetaDataFlag,
  metaDataDropDownList,
  buttonLable,
  module,
  moduleType,
}) => {
  const { keycloak } = useKeycloak();
  const userName = !!keycloak.authenticated ? keycloak.tokenParsed.name : null;
  const [metadata, setMetadata] = useState({
    source: "PromoMats",
  });
  const [isloading, setIsloading] = useState(false);
  //const { module } = useContext(ModuleContext);
  const [languageForCOuntry, setLanguageForCOuntry] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [draftElementsList, setDraftElementsList] = useState([]);

  /**
   * Clone module and go to next page
   */
  const cloneModuleAndGoToNextPage = () => {
    const payload = createModulePayload();
    payload["sourceModuleId"] = module?.moduleId;
    payload["sourceModuleNextUniqueModuleCode"] = module?.nextUniqueModuleCode;
    payload["sourceModuleDamName"] = module?.moduleDamName;
    setIsloading(true);
    cloneModule(module?.moduleId, payload, (res, err) => {
      if (res) {
        gotoNextPage(res.data);
      } else {
        showError(err);
      }
      setIsloading(false);
    });
  };

  /**
   * Creates payload for creating/cloning a module
   */
  const createModulePayload = () => {
    const strategicObject = [];
    if (metadata?.strategicobj?.displayName) {
      strategicObject.push({
        strategicObjDamId: metadata?.strategicobj?.veevaId || undefined,
        strategicObjName: metadata?.strategicobj?.displayName || undefined,
      });
    }
    const payload = {
      source: metadata?.source,
      productDamId: metadata?.brand?.veevaId,
      productName: metadata?.brand?.displayName,
      countryDamId: metadata?.country?.veevaId,
      countryName: metadata?.country?.displayName,
      topicDamId: metadata?.topic?.veevaId,
      topicName: metadata?.topic?.displayName,
      subtopicDamId: metadata?.subtopic?.veevaId,
      subtopicName: metadata?.subtopic?.displayName,
      communicationObjectives: metadata?.communicationObjectives || "",
      brandStyleGuidelines: metadata?.styleGuidelines || "",
      createdByName: userName,
      moduleName: metadata?.moduleName,
      languageDamId: metadata?.language?.veevaId,
      languageName: metadata?.language?.displayName,
      therapeuticAreaDamId: metadata?.theraupaticArea?.veevaId,
      therapeuticAreaName: metadata?.theraupaticArea?.displayName,
      audienceDamId: metadata?.audience?.veevaId,
      audienceName: metadata?.audience?.displayName,
      strategicObjectives: strategicObject,
      segments: getSegmentDataForSave(metadata?.segment),
      indications: getIndicationDataForSave(metadata?.indication),
      draftElementIncluded: getDraftElementsDataForSave(
        metadata?.draftElementIncluded
      ),
    };
    return payload;
  };
  /**
   * Create module and go to the Assemble module screen
   */
  const createModuleAndGoToNextPage = () => {
    const payload = createModulePayload();
    setIsloading(true);
    createModule(payload, (res, err) => {
      setIsloading(false);
      if (res) {
        customEvent();
        gotoNextPage(res.data);
        return;
      }
      showError(err);
    });
  };

  const customEvent = () => {
    cwr("recordEvent", {
      type: "create_new_module",
      data: {
        location: "IAD",
        current_url: window.location.href,
        user_interaction: {
          interaction_1: "click",
        },
        visit_count: 1,
      },
    });
  };
  /**
   * Validate if the mandarory fields are added in metadata
   * @returns true/false
   */
  const checkForEditMetadata = () => {
    if (moduleType === "cloned") {
      return true;
    }
    return (
      module?.claims?.length !== 0 ||
      module?.components?.length !== 0 ||
      module?.reusableTexts?.length !== 0
    );
  };

  const validateMetaDataSelection = () => {
    let allFilled = false;
    if (moduleType !== "cloned" && editMetaDataFlag && checkForEditMetadata()) {
      return false;
    } else {
      allFilled =
        metadata?.brand &&
        metadata?.moduleName?.trim() &&
        metadata?.country &&
        metadata?.topic &&
        metadata?.language &&
        metadata?.communicationObjectives &&
        metadata?.communicationObjectives !== "" &&
        // metadata?.subtopic &&
        metadata?.audience &&
        metadata?.segment?.length > 0 &&
        //metadata?.indication?.length > 0 &&
        (metadata?.country?.veevaId !== "unitedStates" ||
          metadata?.draftElementIncluded?.length > 0) &&
        metadata?.theraupaticArea;
      return allFilled;
    }
  };

  const primaryButton = (
    <PrimaryButton
      title={buttonLable.toUpperCase()}
      handleClick={() => {
        if (moduleType !== "cloned" && editMetaDataFlag && editMetaDataFlag) {
          updateMetaDataAndCloseModal();
        } else if (moduleType === "cloned") {
          cloneModuleAndGoToNextPage();
        } else {
          createModuleAndGoToNextPage();
        }
      }}
      disabled={!validateMetaDataSelection()}
    />
  );

  const CancelButton = (
    <LinkButton
      title={"Cancel".toUpperCase()}
      handleClick={closeMetaDataScreen}
    />
  );
  const handleDropdownSelection = (value, type) => {
    switch (type) {
      case "brand":
        setMetadata((prevState) => {
          return { ...prevState, brand: value, segment: null };
        });
        setSegmentList([]);
        break;
      case "market":
        setMetadata((prevState) => {
          return {
            ...prevState,
            country: value,
            language: null,
            segment: null,
          };
        });
        setSegmentList([]);
        break;

      case "topic":
        setMetadata((prevState) => {
          return { ...prevState, topic: value, subtopic: null };
        });
        break;
      case "subtopic":
        setMetadata((prevState) => {
          return { ...prevState, subtopic: value };
        });
        break;
      case "communicationObjectives":
        setMetadata((prevState) => {
          return { ...prevState, communicationObjectives: value };
        });
        break;
      case "styleGuidelines":
        setMetadata((prevState) => {
          return { ...prevState, styleGuidelines: value };
        });
        break;
      case "segment":
        setMetadata((prevState) => {
          return { ...prevState, segment: value };
        });
        break;
      case "indication":
        setMetadata((prevState) => {
          return { ...prevState, indication: value };
        });
        break;
      case "language":
        setMetadata((prevState) => {
          return { ...prevState, language: value };
        });
        break;
      case "audience":
        setMetadata((prevState) => {
          return { ...prevState, audience: value };
        });
        break;
      case "theraupaticArea":
        setMetadata((prevState) => {
          return { ...prevState, theraupaticArea: value };
        });
        break;
      case "strategicobj":
        setMetadata((prevState) => {
          return { ...prevState, strategicobj: value };
        });
        break;
      case "moduleName":
        setMetadata((prevState) => {
          return { ...prevState, moduleName: value };
        });
        break;
      case "draftElementIncluded":
        setMetadata((prevState) => {
          return { ...prevState, draftElementIncluded: value };
        });
        break;
      default:
        break;
    }
  };
  const updateMetaDataAndCloseModal = () => {
    const strategicObject = [];
    if (metadata?.strategicobj?.displayName) {
      strategicObject.push({
        strategicObjDamId: metadata?.strategicobj?.veevaId || undefined,
        strategicObjName: metadata?.strategicobj?.displayName || undefined,
      });
    }
    const payload = {
      countryDamId: metadata?.country?.veevaId,
      countryName: metadata?.country?.displayName,
      topicDamId: metadata?.topic?.veevaId,
      topicName: metadata?.topic?.displayName,
      subtopicDamId: metadata?.subtopic?.veevaId,
      subtopicName: metadata?.subtopic?.displayName,
      communicationObjectives: metadata?.communicationObjectives || "",
      brandStyleGuidelines: metadata?.styleGuidelines || "",
      moduleName: metadata?.moduleName,
      languageDamId: metadata?.language?.veevaId,
      languageName: metadata?.language?.displayName,
      therapeuticAreaDamId: metadata?.theraupaticArea?.veevaId,
      therapeuticAreaName: metadata?.theraupaticArea?.displayName,
      audienceDamId: metadata?.audience?.veevaId,
      audienceName: metadata?.audience?.displayName,
      strategicObjectives: strategicObject,
      segments: getSegmentDataForSave(metadata?.segment),
      indications: getIndicationDataForSave(metadata?.indication),
      draftElementIncluded: getDraftElementsDataForSave(
        metadata?.draftElementIncluded
      ),
      editedByName: userName,
    };

    setIsloading(true);
    updateModule(payload, module?.moduleId, (res, err) => {
      setIsloading(false);
      if (res) {
        gotoNextPage();
        return;
      }
      showError(err);
    });
  };

  useEffect(() => {
    if (metadata?.country?.veevaId) {
      getLanguagesForSelectedCountry();
    }
  }, [metadata?.country?.veevaId]);

  const getLanguagesForSelectedCountry = () => {
    setIsloading(true);
    getLanguageForCountry(metadata?.country?.veevaId, (res, err) => {
      setIsloading(false);
      if (res) {
        setLanguageForCOuntry(res.data);
        return;
      }
      showError(err);
    });
  };
  useEffect(() => {
    handleDropdownSelection(undefined, "subtopic");
    if (metadata?.topic?.veevaId && metadata?.brand?.veevaId) {
      getSubTopicsData(metadata?.brand?.veevaId, metadata?.topic?.veevaId);
    }
  }, [metadata?.topic?.veevaId, metadata?.brand?.veevaId]);

  const getSubTopicsData = (productDamId, topicDamId) => {
    const payload = {
      productDamIds: [productDamId],
      topicDamIds: [topicDamId],
    };
    setIsloading(true);
    getSubTopics(payload, (res, err) => {
      setIsloading(false);
      if (res) {
        setSubTopics(res.data);
        return;
      }
      showError(err);
    });
  };
  useEffect(() => {
    if (metadata?.country?.veevaId && metadata?.brand?.veevaId) {
      getSegmentData(metadata?.brand?.veevaId, metadata?.country?.veevaId);
    }
  }, [metadata?.country?.veevaId, metadata?.brand?.veevaId]);
  useEffect(() => {
    if (metadata?.country?.veevaId) {
      if (metadata?.country?.veevaId === "unitedStates") {
        getDraftElementsData();
      }
    }
  }, [metadata?.country?.veevaId]);
  const getSegmentData = (productDamId, countryDamId) => {
    setIsloading(true);
    getSegmentList(productDamId, countryDamId, (res, err) => {
      setIsloading(false);
      if (res) {
        setSegmentList(res.data);
        return;
      }
      showError(err);
    });
  };
  const getDraftElementsData = () => {
    setIsloading(true);
    getDraftElementsList((res, err) => {
      setIsloading(false);
      if (res) {
        setDraftElementsList(res.data);
        return;
      }
      showError(err);
    });
  };
  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div
        className="row ml-0 mr-0"
        style={{ marginTop: "-20px !important", borderRadius: "8px" }}
      >
        <LeftPane className="col-md-4 p-0">
          <AuthorInfo
            title="Metadata information"
            source={metadata.source}
            userName={userName}
            module={
              module
                ? moduleType === "cloned"
                  ? undefined
                  : module
                : undefined
            }
          />
        </LeftPane>
        <div
          className="col-md-8 pt-0 pr-0 pb-0 pl-0"
          // style={{ paddingBottom: "10px", paddingTop: "16px" }}
        >
          <RightPane
            metadata={metadata}
            updateMetadata={handleDropdownSelection}
            editMetaDataFlag={editMetaDataFlag}
            module={module}
            setMetadata={setMetadata}
            metaDataDropDownList={metaDataDropDownList}
            checkForEditMetadata={checkForEditMetadata}
            languageForCOuntry={languageForCOuntry}
            subTopics={subTopics}
            moduleType={moduleType}
            segmentList={segmentList}
            draftElementsList={draftElementsList}
          />
        </div>
      </div>
      <FooterButtons
        primaryButton={primaryButton}
        intermediateButtons={[CancelButton]}
        size="md"
      />
    </>
  );
};

const LeftPane = styled.div`
  background-color: ${variables.paleGrayColor};
  margin-top: -17px;
  //margin-left: -20px;
  border-top-left-radius: 8px;
`;

export default MetaDataInformation;
