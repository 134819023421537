import { instance } from "services/axiosConfig";

const getErrorCode = (err) => {
  const errorCode = err.response && err.response.status;
  return errorCode;
};

export const getCSEmailCognitoToken = () => {
  const endPoint = "/api/modulems/cse/auth-token";
  return instance.get(endPoint);
};

export const fetchBrands = (cb) => {
  instance
    .get("/api/modulems/v1/products")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, getErrorCode(err));
    });
};

export const fetchMarkets = (cb) => {
  instance
    .get("/api/modulems/v1/countries")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, getErrorCode(err));
    });
};

export const fetchTopicsAndSubtopics = (cb) => {
  instance
    .get("/api/modulems/v1/topics")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, getErrorCode(err));
    });
};

export const createModule = (payload, cb) => {
  instance
    .post("/api/modulems/v1/modules", payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const fetchTextualElementsTypes = (type, cb) => {
  instance
    .get(`/api/modulems/v1/${type}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
/**
 *
 * @param {*} type
 * will remove this once backend implements the discussed solution
 */
export const fetchWithPromiseTextualElementsTypes = (type, source) => {
  return instance.get(`/api/modulems/v1/${source}/${type}`);
};

export const fetchTextualElements = (payload, type, cb) => {
  let apiUrl = `/api/modulems/v1/dam/${type}?product=${payload.product}&country=${payload.country}`;
  if (payload.pageNo !== undefined && payload.pageSize !== undefined) {
    apiUrl = `${apiUrl}&pageoffset=${payload.pageNo}&pagesize=${payload.pageSize}`;
  }

  instance
    .post(apiUrl, payload.filterData)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const fetchModuleDetails = (moduleId, cb) => {
  instance
    .get(`/api/modulems/v1/modules/${moduleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const addElementsToModule = (elementType, payload, cb) => {
  instance
    .post(`/api/modulems/v1/dam/${elementType}/import`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const fetchAddedModuleElements = (moduleId, type, cb) => {
  let apiUrl = `/api/modulems/v1/modules/${moduleId}/${type}`;
  instance
    .get(apiUrl)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const toggleMustUse = async (moduleId, type, elementId, toggle, cb) => {
  instance
    .patch(
      `/api/modulems/v1/modules/${moduleId}/${type}/${elementId}?mustUse=${toggle}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const removeElementFromModule = async (
  moduleId,
  type,
  elementId,
  cb
) => {
  instance
    .delete(`/api/modulems/v1/modules/${moduleId}/${type}/${elementId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const updateModule = (payload, moduleId, cb) => {
  instance
    .put(`/api/modulems/v1/modules/${moduleId}`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const fetchMetaDataDetails = (cb) => {
  instance
    .get(`/api/modulems/v1/dam/modules/metadata`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const getAllModulesList = (payload, cb) => {
  instance
    .post(
      `/api/modulems/v1/modules/search?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&createdByMe=${payload.createdByMe}`,
      payload?.filterPayLoad
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const getModulesListForBusinessRules = (payload, cb) => {
  const pageNumber = payload.page;
  delete payload.page;
  instance
    .post(
      `/api/modulems/v1/modules/businessRules?pageNo=${pageNumber}&pageSize=20`,
      payload
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const publishDynamicModule = (payload, cb) => {
  instance
    .post(
      `/api/modulems/v1/dam/modules/${payload.moduleId}/publish
    `,
      { html: payload.html }
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const downloadAnnotationPDF = (docId, cb) => {
  instance
    .get(`/api/modulems/v1/dam/cmsd/${docId}/annotation/download`, {
      responseType: "blob",
    })
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const getModuleBusinessRules = (moduleId, cb) => {
  instance
    .get(`/api/modulems/v1/modules/businessRules/${moduleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const saveBusinessRules = (moduleId, payload, cb) => {
  instance
    .put(
      `/api/modulems/v1/modules/businessRules/${moduleId}
    `,
      payload
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const getLanguageForCountry = (countryDamId, cb) => {
  instance
    .get(`/api/modulems/v1/dam/countries/${countryDamId}/languages`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const publishBusinessRulesToDam = (payload, cb) => {
  instance
    .post(
      `api/modulems/v1/dam/modules/${payload.moduleId}/businessRules/publish
    `,
      { html: payload.html }
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const fetchallThumbnailImages = (imageIds, type, cb) => {
  Promise.allSettled(
    imageIds.map((id) =>
      instance.get(`/api/modulems/v1/dam/components/${id}/file`)
    )
  )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const getSubTopics = (payload, cb) => {
  instance
    .post(`/api/modulems/v1/dam/subtopics`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const syncMLRStatusOfModules = (moduleIds, cb) => {
  instance
    .patch(`/api/modulems/v1/dam/modules/mlrStatuses`, moduleIds)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const updateNotificationSettings = (payload, cb) => {
  instance
    .patch(`/api/modulems/users/notifications`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const getNotificationSettings = (cb) => {
  instance
    .get(`/api/modulems/users/notifications`)
    .then((response) => {
      cb(response.data);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const fetchSubTypes = (cb) => {
  instance
    .get(`/api/modulems/v1/dam/document/subtypes`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const fetchClassifications = (subType, cb) => {
  instance
    .get(`/api/modulems/v1/dam/document/classifications?subtype=${subType}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const downloadCMSD = (payload, cb) => {
  instance
    .post(
      `/api/modulems/v1/modules/${payload.moduleId}/cmsd/file`,
      {
        html: payload.html,
      },
      { responseType: "blob" }
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const getCMSDTrackChanges = (moduleId, cb) => {
  instance
    .get(`/api/modulems/v1/modules/${moduleId}/cmsd/trackChanges`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const fetchAllMlrStatuses = (cb) => {
  instance
    .get(`/api/modulems/v1/dam/cmsd/mlrStatuses`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const downloadCmsdTrackFile = (payLoad, cb) => {
  instance
    .get(
      `/api/modulems/v1/dam/modules/${payLoad.moduleId}/cmsd/${payLoad.cmsdDocId}/${payLoad.cmsdMajorV}/${payLoad.cmsdMinorV}/file`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const deleteModule = (moduleId, cb) => {
  instance
    .delete(`/api/modulems/v1/modules/${moduleId}`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const cloneModule = (sourceModuleId, payload, cb) => {
  instance
    .post(`/api/modulems/v1/modules/${sourceModuleId}/clone`, payload)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const saveAssociatedModules = (sourceModuleId, payload, cb) => {
  instance
    .post(
      `/api/modulems/v1/modules/${sourceModuleId}/businessRules/associatedModules
  `,
      payload
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const getAssociatedModules = (sourceModuleId, cb) => {
  instance
    .get(
      `/api/modulems/v1/modules/${sourceModuleId}/businessRules/associatedModules`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const fetchModuleCounts = (cb) => {
  instance
    .get("/api/modulems/v1/modules/summary")
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, getErrorCode(err));
    });
};

export const getSegmentList = (productDamId, countryDamId, cb) => {
  instance
    .post(
      `/api/modulems/v1/dam/segments?productDamId=${productDamId}&countryDamId=${countryDamId}`
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};

export const fetchModuleComponents = (moduleId, cb) => {
  const endPoint = `/api/modulems/v1/modules/${moduleId}/components`;

  instance
    .get(endPoint)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const getExpiryNotificationData = (type, cb) => {
  const url = type
    ? `/api/modulems/v1/mlrStatusNotifications/userMlrStatusNotifications?type=${type}`
    : `/api/modulems/v1/mlrStatusNotifications/userMlrStatusNotifications`;
  instance
    .get(url)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err, getErrorCode(err));
    });
};

export const markAsReadNotification = (payload, cb) => {
  instance
    .post(
      `/api/modulems/v1/mlrStatusNotifications/userMlrStatusNotifications`,
      payload
    )
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
export const getDraftElementsList = (cb) => {
  instance
    .get(`/api/modulems/v1/draftElementsIncluded`)
    .then((response) => {
      cb(response);
    })
    .catch((err) => {
      cb(undefined, err);
    });
};
