import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { renderToString } from "react-dom/server";
import styles from "styles/modules/modulePreview.module.scss";
import downArrow from "img/blue-down-arrow.svg";
import upArrow from "img/blue-up-arrow.svg.svg";
import reload from "img/reload.svg";
import CustomDropdownMenu from "components/customDropdownMenu";
import ModulePreviewData from "components/modules/ModulePreviewData";
import PrimaryButton from "components/PrimaryButton";
import { ModuleContext, moduleActionTypes } from "contexts/ModuleContext";
import LinkButton from "components/LinkButton";
import {
  publishBusinessRulesToDam,
  publishDynamicModule,
  downloadCMSD,
  getCMSDTrackChanges,
  downloadAnnotationPDF,
  downloadCmsdTrackFile,
} from "services/apis";
import {
  getCurrentUser,
  isEmptyObject,
  routes,
  showError,
  emitter,
} from "Uitls/Util";
import CircularProgressBar from "Uitls/CircularProgressBar";
import SuccessfulToasMessage from "components/SuccessfulToastMessage";
import SuccessfulToastMessage from "components/SuccessfulToastMessage";
import PrimaryButtonOutline from "components/PrimaryButtonOutline";
import { getAllModulesList, syncMLRStatusOfModules } from "services/apis";
import OverlayPopOver from "Uitls/OverlayPopOver";
import { ToolTipText } from "components/TooltipText";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getMlrStatusIcon } from "Uitls/Util";
import RoundedTransparentButton from "components/RoundedTransparentButton";
import UnsuccessfulToastMessage from "components/UnsuccessfulToastMessage";
import UserActionsDropdown from "../UserActionDropDownButton";

import moment from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CloneModule } from "components/modules/clone/CloneModule";
import OverlayPopOverToolTip from "Uitls/OverlayPopOverToolTip";
function ModulePreview(props) {
  let history = useHistory();
  const { t } = useTranslation();
  const {
    setActiveScreen,
    moduleScreens,
    modulePreviewData,
    isBussinessRulesView,
    clicked,
    setPreviewScreen,
    preview,
    setModulePreviewData,
  } = props;

  const [isloading, setIsloading] = useState(false);
  const { module, updateModule } = useContext(ModuleContext);
  const [activePreviwData, toggleActivePreviwData] = useState(true);
  const [isUserActionDropdownOpen, setIsUserActionDropdownOpen] =
    useState(false);
  const [isTrackChanges, setIsTrackChanges] = useState([]);
  const [trackChangesBtnEnable, setTrackChangesBtnEnable] = useState(false);
  //this is for Clone module
  const [moduleIdToBeCloned, setModuleIdToBeCloned] = useState(undefined);
  const [autoSyncData, setAutoSyncData] = useState(undefined);

  useEffect(() => {
    if (autoSyncData) {
      if (autoSyncData.type !== "Module") {
        let moduleDetails = JSON.parse(JSON.stringify(module));
        if (autoSyncData?.type === "Claim") {
          let index = moduleDetails?.claims?.findIndex(
            (elem) => elem.claimDamId === autoSyncData?.id
          );
          if (index > -1) {
            moduleDetails.claims[index].mlrStatus = autoSyncData?.mlrStatus;
          }
          if (moduleDetails.components.length > 0) {
            let components = JSON.parse(
              JSON.stringify(moduleDetails.components)
            ).map((item) => {
              let newData = [...item.relatedClaims];
              let index = newData?.findIndex(
                (elem) => elem.claimDamId === autoSyncData?.id
              );
              if (index > -1) {
                newData[index].mlrStatus = autoSyncData.mlrStatus;
                item.relatedClaims = newData;
              }
              return item;
            });
            moduleDetails.components = components;
          }
        }
        if (autoSyncData?.type === "Reusable Text") {
          let index = moduleDetails?.reusableTexts?.findIndex(
            (elem) => elem.reusableTextDamId === autoSyncData?.id
          );
          if (index > -1) {
            moduleDetails.reusableTexts[index].mlrStatus =
              autoSyncData?.mlrStatus;
          }
          if (moduleDetails.components.length > 0) {
            let components = JSON.parse(
              JSON.stringify(moduleDetails.components)
            ).map((item) => {
              let newData = [...item.relatedReusableTexts];
              let index = newData?.findIndex(
                (elem) => elem.reusableTextDamId === autoSyncData?.id
              );
              if (index > -1) {
                newData[index].mlrStatus = autoSyncData.mlrStatus;
                item.relatedReusableTexts = newData;
              }
              return item;
            });
            moduleDetails.components = components;
          }
        }
        if (autoSyncData?.type === "Component") {
          let index = moduleDetails?.components?.findIndex(
            (elem) => elem.componentDamId === autoSyncData?.id
          );
          if (index > -1) {
            moduleDetails.components[index].mlrStatus = autoSyncData?.mlrStatus;
          }
        }
        updateModule(moduleActionTypes.SET_MODULE, moduleDetails);
        setModulePreviewData(moduleDetails);
        if (trackChangesBtnEnable) {
          let isTrackChangesModuleDetails = JSON.parse(
            JSON.stringify(isTrackChanges)
          );

          if (autoSyncData?.type === "Claim") {
            let index = isTrackChangesModuleDetails?.claims?.findIndex(
              (elem) => elem.claimDamId === autoSyncData?.id
            );
            if (index > -1) {
              isTrackChangesModuleDetails.claims[index].mlrStatus =
                autoSyncData?.mlrStatus;
            }
            if (isTrackChangesModuleDetails.components.length > 0) {
              let components = JSON.parse(
                JSON.stringify(isTrackChangesModuleDetails.components)
              ).map((item) => {
                let newData = [...item.relatedClaims];
                let index = newData?.findIndex(
                  (elem) => elem.claimDamId === autoSyncData?.id
                );
                if (index > -1) {
                  newData[index].mlrStatus = autoSyncData.mlrStatus;
                  item.relatedClaims = newData;
                }
                return item;
              });
              isTrackChangesModuleDetails.components = components;
            }
          }
          if (autoSyncData?.type === "Reusable Text") {
            let index = isTrackChangesModuleDetails?.reusableTexts?.findIndex(
              (elem) => elem.reusableTextDamId === autoSyncData?.id
            );
            if (index > -1) {
              isTrackChangesModuleDetails.reusableTexts[index].mlrStatus =
                autoSyncData?.mlrStatus;
            }
            if (isTrackChangesModuleDetails.components.length > 0) {
              let components = JSON.parse(
                JSON.stringify(isTrackChangesModuleDetails.components)
              ).map((item) => {
                let newData = [...item.relatedReusableTexts];
                let index = newData?.findIndex(
                  (elem) => elem.reusableTextDamId === autoSyncData?.id
                );
                if (index > -1) {
                  newData[index].mlrStatus = autoSyncData.mlrStatus;
                  item.relatedReusableTexts = newData;
                }
                return item;
              });
              isTrackChangesModuleDetails.components = components;
            }
          }
          if (autoSyncData?.type === "Component") {
            let index = isTrackChangesModuleDetails?.components?.findIndex(
              (elem) => elem.componentDamId === autoSyncData?.id
            );
            if (index > -1) {
              isTrackChangesModuleDetails.components[index].mlrStatus =
                autoSyncData?.mlrStatus;
            }
          }
          setIsTrackChanges(isTrackChangesModuleDetails);
        }
      } else {
        if (
          autoSyncData.type === "Module" &&
          (autoSyncData.id === module.moduleId || autoSyncData.id === module.id)
        ) {
          updateModule(moduleActionTypes.SYCH_MLR_STATUS, {
            mlrStatus: autoSyncData.mlrStatus,
          });
          if (localStorage.getItem("bsrulesPageData")) {
            let data = JSON.parse(localStorage.getItem("bsrulesPageData"));
            if (data.id === autoSyncData.id) {
              data.mlrStatus = autoSyncData?.mlrStatus;

              localStorage.setItem("bsrulesPageData", JSON.stringify(data));
            }
          }
        }
      }
    }
  }, [autoSyncData]);
  useEffect(() => {
    const handleFooEvent = (e) => {
      try {
        const parsedData = JSON.parse(e);
        if (
          parsedData.type === "Claim" ||
          parsedData.type === "Reusable Text" ||
          parsedData.type === "Component" ||
          parsedData.type === "Module"
        ) {
          setAutoSyncData(parsedData);
        }
      } catch (error) {
        console.error("Received non-JSON message:", e);
      }
    };

    emitter.on("autoSync", handleFooEvent);

    return () => {
      emitter.off("autoSync", handleFooEvent);
    };
  }, []);
  useEffect(() => {
    if (module?.mlrStatus === "Revise and Resubmit") {
      showCmsdTrackChanges(false);
    }
  }, []);
  const dropDownItems = [
    {
      id: 1,
      name: "Edit Module",
      action: "Edit Module",
    },
    {
      id: 2,
      name: "Download CMSD",
      action: "Download CMSD",
    },
    {
      id: 3,
      name: "View Track Changes",
      action: "View Track Changes",
      disabled: true,
    },
    {
      id: 4,
      name: "View MLR Feedback",
      action: "View MLR Feedback",
      disabled: true,
    },
  ];
  const customToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="customDropdownOutline"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        setIsUserActionDropdownOpen(!isUserActionDropdownOpen);
      }}
    >
      User Action(s){" "}
      <img
        src={isUserActionDropdownOpen ? upArrow : downArrow}
        alt="User Action(s)"
      />
    </div>
  ));

  const showPublishSuccess = (response) => {
    // toast.error(errCoponent);
    // const toastMessage = `${isBussinessRulesView ? "Business Rules" : "Module"}
    const toastMessage = `Module has been successfully published to PromoMats. An email notification will be sent to you once CMSD is published.`;
    toast.success(
      <SuccessfulToastMessage
        message={toastMessage}
        otherDetails={{
          "PromoMats Module Doc ID": response.moduleDamName,
          "Published by": response.publishedByName,
          "Published on":
            moment(response.publishedOn).format("DD/MM/yyyy, HH:MM:ss") + "Hrs",
        }}
        handleClose={() => {
          toast.dismiss();
          setTimeout(() => {
            history.push({
              pathname: routes.dashboard,
              state: {
                selectedTab: isBussinessRulesView ? 1 : 0,
              },
            });
          }, 5000);
        }}
      />,
      {
        autoClose: 5000,
        position: "top-right",
        closeButton: false,
        onClose: () => {
          history.push({
            pathname: routes.dashboard,
            state: {
              selectedTab: isBussinessRulesView ? 1 : 0,
            },
          });
        },
      }
    );
  };

  const showPublishunsuccessful = (errorCode) => {
    let errorMessage = "";

    if (errorCode === "INVALID_MODULE_PUBLISH_STATE") {
      errorMessage =
        "Module Publish failed because the status is not in ‘Draft’ or ‘Revise & Resubmit’.";
    } else {
      errorMessage =
        "Module could not be published. Please try again. If you are not able to publish in 3 attempts, contact system support.";
    }

    const handleClose = () => {
      toast.dismiss(); // Close the toast notification
    };

    toast.error(
      <UnsuccessfulToastMessage
        message={errorMessage}
        handleClose={handleClose}
      />,
      {
        autoClose: 5000,
        position: "top-right",
        closeButton: false,
      }
    );
  };

  const handleDownloadCMSD = () => {
    setIsloading(true);
    const moduleObj = {
      moduleId: module?.moduleId,
      html: generateCmsdData("downloadcmsd"),
    };
    downloadCMSD(moduleObj, (response, err) => {
      setIsloading(false);
      if (err) {
        showError(err);
      } else {
        setIsloading(false);
        downloadDocx(response.data);
      }
    });
  };

  const downloadCmsdDcoument = () => {
    setIsloading(true);
    const payLoad = {
      moduleId: module?.moduleId,
      cmsdDocId: module?.cmsdDocId || null,
      cmsdMajorV: module?.cmsdMajorV || null,
      cmsdMinorV: module?.cmsdMinorV || null,
    };
    downloadCmsdTrackFile(payLoad, (response, err) => {
      setIsloading(false);
      if (err) {
        showError(err);
      } else {
        downloadDocx(response.data);
      }
    });
  };

  const handleDownloadAnnotationPDF = () => {
    setIsloading(true);
    const docId = module?.cmsdDocId;

    downloadAnnotationPDF(docId, (response, err) => {
      setIsloading(false);

      if (err) {
        showError(err);
      } else {
        downloadDocx(response.data);
      }
    });
  };

  const publishModule = () => {
    setIsloading(true);
    const nccaModuleId = module?.moduleId;
    const publishPayload = {
      moduleId: module?.moduleId,
      html: generateCmsdData(),
    };
    if (nccaModuleId) {
      publishDynamicModule(publishPayload, (res, err) => {
        if (res) {
          updateModule(moduleActionTypes.MODULE_PUBLISH, {
            moduleDamId: res.data.moduleDamId,
            mlrStatus: res.data.mlrStatus,
          });
          showPublishSuccess(res.data);
          //history.push(routes.dashboard);
        } else {
          const errorCode = err.response.data && err.response.data.errorCode;
          showPublishunsuccessful(errorCode);
          //showError(err);
        }
        setIsloading(false);
      });
    } else {
      setIsloading(false);
    }
  };
  const publishBusinessRules = () => {
    setIsloading(true);
    const nccaModuleId = modulePreviewData?.moduleId;
    const publishPayload = {
      moduleId: modulePreviewData?.moduleId,
      html: generateCmsdData(),
    };
    if (nccaModuleId) {
      publishBusinessRulesToDam(publishPayload, (res, err) => {
        if (res) {
          updateModule(moduleActionTypes.BUSINESSRULES_PUBLISH, {
            businessRulePublished: true,
          });
          showPublishSuccess(res.data);
          //history.push(routes.dashboard);
        } else {
          const errorCode = err.response.data && err.response.data.errorCode;

          showPublishunsuccessful(errorCode);
          //showError(err);
        }
        setIsloading(false);
      });
    } else {
      setIsloading(false);
    }
  };

  const shouldFooterPublishBeDisabled = () => {
    // if (isBussinessRulesView) {
    //   return modulePreviewData?.businessRulePublished === null &&
    //     !module?.businessRulePublished
    //     ? false
    //     : true;
    // } else {
    //   return (
    //     module?.mlrStatus === null &&
    //     ["draft", "revise and resubmit"].includes(
    //       module?.mlrStatus?.toLowerCase()
    //     )
    //   );
    // }
    if (isloading) {
      return true;
    }

    const hasApprovedForUseStatus = (arr) =>
      arr?.every(
        (item) =>
          item.mlrStatus?.toLowerCase() === "available for use" ||
          (module?.countryDamId === "unitedStates" &&
            item.mlrStatus?.toLowerCase() === "draft")
      );
    // Check nested arrays in components
    // const hasApprovedForUseInComponents = module?.components?.every(component => {
    //   return (
    //     component.mlrStatus?.toLowerCase() === 'approved for use' &&
    //     hasApprovedForUseStatus(component.relatedClaims) &&
    //     hasApprovedForUseStatus(component.relatedReusableTexts)
    //   );
    // });
    return (
      module?.mlrStatus?.toLowerCase() === "expired" ||
      (module?.mlrStatus &&
        !["draft", "revise and resubmit"].includes(
          module?.mlrStatus?.toLowerCase()
        )) ||
      !(
        hasApprovedForUseStatus(module?.claims) &&
        hasApprovedForUseStatus(module?.reusableTexts) &&
        hasApprovedForUseStatus(module?.components)
      )
    );

    // return (
    //   (module?.mlrStatus === null &&
    //     ["draft", "revise and resubmit"].includes(
    //       module?.mlrStatus?.toLowerCase()
    //     )) ||
    //   module?.mlrStatus === "In Business Owner Review"
    // );
  };

  const openVeevaVault = (url) => {
    window.open(url, "_blank");
  };

  const syncMLRStatus = (moduleId) => {
    setIsloading(true);
    let payload = [
      {
        moduleId: module?.moduleId,
      },
    ];
    syncMLRStatusOfModules(payload, (res, err) => {
      setIsloading(false);
      if (err) {
        showError(err);
      } else {
        if (res.data.length > 0) {
          updateModule(moduleActionTypes.SYCH_MLR_STATUS, {
            mlrStatus: res.data[0]?.mlrStatus,
          });
        }
      }
    });
  };

  const generateCmsdData = (type) => {
    const cmsdData = document.getElementById("modulePreviewContainer");
    const cmsdhtml = cmsdData.innerHTML;
    const VeevaDocID = "${VeevaDocID}";
    const cretedBy = "${cretedBy}";
    let cretedOn = "${cretedOn}";
    const finalHtmlContent = `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
    
        <style>
          @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,600;1,6..12,700&display=swap");
    
          body {
            font-size: 14px;
            font-family: "Nunito Sans", sans-serif !important;
            padding:20px;
          }
          .titleBackground {
            border-bottom: solid 1px #e6e6ec;
            padding-bottom: 16px;
          }
          .previewTitle {
            line-height: normal;
            font-weight: 700;
            color: #252529;
          }
          .claimId img {
            margin-right: 2px;
            align-items: center;
            margin-top: -2px;
          }
          .previewTitle span {
            font-size: 12px;
            color: #54565b;
            display: block;
            font-weight: normal;
          }
          .mlrStatus {
            font-size: 14px;
            color: #1e1e1e;
          }
          .mlrStatus span {
            font-weight: 600;
          }
          .plr1100 {
            padding-left: 110px;
            padding-right: 110px;
            min-height: calc(100vh - 227px);
          }
          .modulePreviewContainer {
            border: solid 1px #e6e6eb;
          }
          .modulePreviewHeader {
            padding: 20px;
            border-bottom: solid 1px #e6e6eb;
            background-color: #f3f6fa;
          }
          .modulePreviewHeader p {
            
            font-weight: 600;
            color: #1e1e1e;
            margin-bottom: 10px;
            line-height: 22px;
            font-size: 14px;
          }
          .modulePreviewHeader p span {
            font-weight: normal;
            color: #54565b !important;
            margin-left: 20px;
            margin-right: 2px;
            display: inline-block;
          }
    
          .moduleClaimSection {
            padding: 10px 20px 40px 20px;
          }
    
          .claimBoxBrdr {
            padding: 12px;
            border-radius: 10px;
            border: solid 1px #e6e6eb;
            background-color: #fff;
            margin-right: 20px;
            line-height: normal;
          }
          .claimBoxBrdr .claimType {
            font-weight: bold;
            color: #1e1e1e;
            text-transform: uppercase;
            margin-bottom: 6px;
          }
          .claimBoxBrdr .damStatus {
            margin-bottom: 6px;
          }
          .claimBoxBrdr .damStatus img {
            margin-right: 3px;
            margin-top: -2px;
          }
          .claimBoxBrdr .damStatus span {
            display: inline-block;
            line-height: 12px;
            font-weight: 600;
            color: #1e1e1e;
            vertical-align: middle;
          }
          .claimBoxBrdr .claimId span {
            color: #203662;
            font-weight: 700;
          }
          .claimBoxBrdr .claimTypeText {
            font-weight: normal;
            color: #54565b;
            margin-bottom: 6px;
          }
          .claimBoxBrdr .claimTypeText span {
            font-weight: 600;
          }
    
          .claimDetailsBox {
            padding-left: 20px;
            flex: 1 1 0;
          }
          .claimText {
            font-weight: 600;
            color: #1e1e1e;
            margin-bottom: 20px;
          }
          .captionText {
            font-weight: 600;
            color: #54565b;
            margin-bottom: 10px;
            margin-top: 20px;
            text-transform: uppercase;
          }
          .relatedText {
            font-weight: 600;
            color: #54565b;
            margin-bottom: 12px;
            margin-bottom: 0 !important;
          }
          .relatedText a {
            color: #54565b;
            text-decoration: none;
          }
          .sectionDevider {
            border-bottom: dashed 1px #878888;
            margin-bottom: 40px;
            margin-top: 40px;
            margin-left: 20px;
            margin-right: 20px;
          }
    
          .modulePreviewFooter {
            padding-top: 10px;
            padding-bottom: 10px;
            box-shadow: 1px -3px 10px 0 rgba(0, 0, 0, 0.07) !important;
            background-color: #fff;
            text-align: right;
            position: relative;
          }
    
          .modulePrviewContainer {
            height: calc(100vh - 223px);
            overflow-y: scroll;
            overflow-x: hidden;
          }
    
          .bsrulesPreview .modulePrviewContainer {
            height: calc(100vh - 280px);
            overflow-y: scroll;
            overflow-x: hidden;
          }
    
          .brulesTable {
            border-collapse: collapse;
            width: 100%;
          }
          .brulesTableHeading {
            background-color: #203662;
          }
          .tdHeading {
            font-weight: bold;
            color: #fff;
          }
          .tdCommon {
            font-size: 14px;
            text-align: left;
            padding-top: 12px !important;
            padding-bottom: 12px !important;
            padding-left: 21px !important;
            border: solid 1px #e6e6eb;
          }
          .tdlable {
            font-weight: 600;
            color: #1e1e1e;
          }
          .tdValue {
            font-weight: 600;
            color: #203662;
          }
          .tdValue2 {
            font-size: 14px;
            color: #1e1e1e;
          }
          .tdForValueTable {
            border: solid 1px #e6e6eb;
          }
          .commonPadding {
            padding-left: 110px;
            padding-right: 110px;
          }
          .brulesTableNoBrdr td {
            border-left: 0;
            border-right: 0;
          }
          .brulesTableNoBrdr td:last-child {
            border-bottom: 0;
          }
          .Expired {
            font-size: 8px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            background-color: #a9aaac;
            padding: 2px 4px;
            border-radius: 3px;
            margin-left: 6px;
            } 
          .usageGuidilines {
            font-weight: normal;
            color: "##1e1e1e";
            margin-bottom: 0;
          }
          .d-flex {
            display: flex;
          }
          .ml-0 {
            margin-left: 0px !important;
          }
          .p-0 {
            padding: 0;
          }
          .align-items-center {
            align-items: center !important;
        }
        mb-2, .my-2 {
          margin-bottom: .5rem !important;
      }
      .img-fluid {
          max-width: 100%;
          height: auto;
      }
      img {
          vertical-align: middle;
          border-style: none;
      }
      /*Track changes styles starts */
      .bsOld {
        background-color: #d4d4d4 !important;
        display: inline-block;
        border-radius: 4px;
        padding: 2px 5px;
        font-size: 14px;
        font-weight: 600;
        color: #1e1e1e;
        margin-bottom: 6px;
        text-transform: capitalize;
        margin-top: 15px;
      }
      .cmsdTrackContainer .newData {
        display: inline-block;
        background-color: #ffeab4;
        border-radius: 4px;
        padding: 2px 5px;
        font-size: 14px;
        font-weight: 600;
        color: #1e1e1e;
        margin-bottom: 6px;
        text-transform: capitalize;
        margin-top: 15px;
      }
      .cmsdTrackContainer .newData.oldData {
        background-color: #d4d4d4;
      }
      .cmsdTrackContainer .trackChangeValue {
        background-color: rgba(255, 240, 202, 0.5);
        border-radius: 10px;
      }
      .cmsdTrackContainer .trackChangeValue.old {
        background-color: #f7f7f7;
      }
      .cmsdTrackContainer .trackChangeValue.old .claimDetailsBox {
        text-decoration: line-through;
      }
      .cmsdTrackContainer .trackChangeValue.old .claimDetailsBox .claimText {
        color: #878888;
      }
      .cmsdTrackContainer .trackChangeValue.old .claimId {
        color: #878888;
      }
      .cmsdTrackContainer .trackChangeValue.old .claimBoxBrdr .damStatus span {
        color: #878888;
      }
      .cmsdTrackContainer .modulePreviewContainer {
        border: none;
      }
      .cmsdTrackContainer .moduleClaimSection {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .cmsdTrackContainer .trackChangeValue.old .claimDetailsBox .captionText {
        color: #878888;
      }
      .cmsdTrackContainer .trackChangeValue.old .claimDetailsBox .relatedText {
        color: #878888;
      }
    /*Track changes styles ends */
        </style>
      </head>
      <body>
        <div class="col-md-12 plr110">   
       
          <div class="col-md-12 plr110">
            ${renderToString(getcmsdData(type))}
          </div>
        </div>
      </body>
    </html>`;
    return finalHtmlContent;
  };

  const downloadDocx = (response) => {
    let url = window.URL.createObjectURL(new Blob([response]));
    let link = document.createElement("a");
    const promoMatsID = module?.moduleName || module?.nextUniqueModuleCode;
    link.href = url;
    link.setAttribute("download", `${promoMatsID}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const showCmsdTrackChanges = (clicked) => {
    setIsloading(true);
    let moduleId = module?.moduleId ? module?.moduleId : module?.id;
    getCMSDTrackChanges(moduleId, (res, err, errCode) => {
      if (res) {
        setIsloading(false);
        if (!res.data.moduleId) {
          return;
        }
        setIsTrackChanges(res.data);
        setTrackChangesBtnEnable(findModuleHasTrackChanges(res.data));
        if (clicked) {
          toggleActivePreviwData(false);
        }
      } else {
        setIsloading(false);
        showError(err);
      }
    });
  };

  const getcmsdData = (type) => {
    if (type === "downloadcmsd") {
      return (
        <ModulePreviewData
          modulePreviewData={modulePreviewData}
          isBussinessRulesView={isBussinessRulesView}
          generatePdf={true}
        />
      );
    } else {
      return (
        <ModulePreviewData
          modulePreviewData={
            module?.mlrStatus === "Revise and Resubmit" &&
            isTrackChanges.moduleId !== null
              ? isTrackChanges
              : modulePreviewData
          }
          isBussinessRulesView={isBussinessRulesView}
          activePreviwData={
            module?.mlrStatus === "Revise and Resubmit" &&
            isTrackChanges.moduleId !== null
              ? false
              : true
          }
          generatePdf={true}
        />
      );
    }
  };
  const findModuleHasTrackChanges = (data) => {
    if (data.moduleId === null) {
      return;
    }
    const result =
      data?.claims.some((text) => text.trackChangeValue) ||
      data?.reusableTexts.some((text) => text.trackChangeValue) ||
      data?.businessRulesTrackChanges !== null ||
      data?.components.some((component) => component.trackChangeValue);
    return result;
  };
  return (
    <>
      <CircularProgressBar isloading={isloading} />
      <div className={`row mt-16 align-items-center ${styles.titleBackground}`}>
        <div className="col-md-4">
          <div className={`${styles.btngrpCMSD}`}>
            <RoundedTransparentButton
              title="CMSD Preview"
              variant="light"
              clicked={activePreviwData}
              handleClick={() => {
                toggleActivePreviwData(true);
              }}
            />
            <RoundedTransparentButton
              title="CMSD Preview with Track changes"
              variant="light"
              clicked={!activePreviwData}
              handleClick={() => {
                showCmsdTrackChanges(true);
                //toggleActivePreviwData(false);
              }}
              btnStatus={!trackChangesBtnEnable}
            />
          </div>
          {/* {isBussinessRulesView && " with Business Rules"}
            <span>View selected and inserted information.</span> */}
        </div>
        <div className="col-md-8 text-right">
          <div className="d-flex align-items-center justify-content-end">
            <div className={` ${styles.mlrStatus} mr25`}>
              Content Module ID:{" "}
              {modulePreviewData?.moduleDamName ? (
                <span
                  onClick={() => {
                    if (modulePreviewData?.cmsdDocUrl) {
                      openVeevaVault(modulePreviewData?.cmsdDocUrl);
                    }
                  }}
                  style={{
                    color: "#203662",
                    cursor: modulePreviewData?.cmsdDocUrl
                      ? "pointer"
                      : "default",
                  }}
                >
                  {modulePreviewData?.moduleDamName}
                </span>
              ) : (
                "-"
              )}
            </div>

            <div className="mr-1 align-items-center">
              {modulePreviewData?.mlrStatus === null ? (
                <div>
                  <img
                    style={{ verticalAlign: "unset" }}
                    src={getMlrStatusIcon(null)}
                    alt={"Module not Published"}
                  />
                </div>
              ) : (
                <div>
                  <img
                    style={{ verticalAlign: "unset" }}
                    src={getMlrStatusIcon(module?.mlrStatus)}
                    alt={module?.mlrStatus}
                  />
                </div>
              )}
            </div>

            <div className={` ${styles.mlrStatus}`}>
              MLR Status:{" "}
              <span>
                {module?.mlrStatus ? module?.mlrStatus : "Not Published"}
              </span>
              <button
                className="btn btn-link ml-1 p-0 centerAlign"
                onClick={() => syncMLRStatus(module.id)}
              >
                <img
                  style={{ verticalAlign: "unset" }}
                  src={reload}
                  alt="Reload"
                />
              </button>
            </div>

            <div className="ml-3 ">
              <UserActionsDropdown
                module={module}
                modulePreviewData={modulePreviewData}
                handleDownloadCMSD={handleDownloadCMSD}
                downloadCmsdDcoument={downloadCmsdDcoument}
                handleDownloadAnnotationPDF={handleDownloadAnnotationPDF}
                setModuleIdToBeCloned={setModuleIdToBeCloned}
              />
              {/* <DropdownButton
                className="userActionsDropdown"
                id="dropdown-item-button"
                title="User Action(s)"
              >
                <ul>
{/* <li>
                      <button
                        className="btn btn-link p-0 m-0"
                        disabled={
                          modulePreviewData?.mlrStatus !== null &&
                          !["draft", "revise and resubmit"].includes(
                            modulePreviewData?.mlrStatus?.toLowerCase()
                          )
                        }
                      >
                        Edit Module
                      </button>
                    </li> */}
              {/*<li>
                    <button
                      className="btn btn-link p-0 m-0"
                      onClick={handleDownloadCMSD}
                      disabled={
                        !module?.mlrStatus || module?.mlrStatus === null
                      }
                    >
                      Download CMSD - Clean version
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-link p-0 m-0"
                      onClick={downloadCmsdDcoument}
                      disabled={
                        module?.mlrStatus !== "Revise and Resubmit" ||
                        module?.cmsdMajorV === null
                      }
                    >
                      Download CMSD - Track changes
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-link p-0 m-0"
                      disabled={
                        !["revise and resubmit"].includes(
                          modulePreviewData?.mlrStatus?.toLowerCase()
                        )
                      }
                      onClick={handleDownloadAnnotationPDF}
                    >
                      Download MLR Feedback
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-link p-0 m-0"
                      onClick={() => {
                        setModuleIdToBeCloned(module.id || module.moduleId);
                      }}
                    >
                      {t("module.cloneModule")}
                    </button>
                  </li>
                </ul>
              </DropdownButton> */}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingRight: "4px",
          backgroundColor: "#f9f9fa",
        }}
      >
        <div
          className={`row customScrollBar ${styles.modulePrviewContainer}`}
          key={activePreviwData ? 0 : 1}
        >
          {activePreviwData === true ? (
            <ModulePreviewData
              modulePreviewData={modulePreviewData}
              isBussinessRulesView={isBussinessRulesView}
            />
          ) : (
            <ModulePreviewData
              modulePreviewData={isTrackChanges}
              isBussinessRulesView={isBussinessRulesView}
              activePreviwData={activePreviwData}
            />
          )}
        </div>
      </div>
      <div className={`row align-items-center ${styles.modulePreviewFooter}`}>
        <div className="col-md-6 text-left">
          {!preview && (
            <LinkButton
              title="BACK"
              handleClick={() => {
                setActiveScreen(moduleScreens?.home);
              }}
            />
          )}
        </div>
        <div className="col-md-6 text-right">
          <PrimaryButtonOutline
            title={`Go to Dashboard`}
            handleClick={() => {
              const selectTab = isBussinessRulesView ? 1 : 0;
              history.push({
                pathname: routes.dashboard,
                state: {
                  selectedTab: selectTab,
                },
              });
            }}
            minWidth={168}
            //icon={addIcon}
            btnType="outline"
            showIcon={false}
          />
          {shouldFooterPublishBeDisabled() ? (
            <OverlayPopOverToolTip
              element={
                <div>
                  <PrimaryButton
                    title="PUBLISH"
                    handleClick={() => {
                      // if (isBussinessRulesView) {
                      //   publishBusinessRules();
                      //   return;
                      // }
                      publishModule(module.id);
                    }}
                    minWidth={110}
                    marginLeft={20}
                    disabled={shouldFooterPublishBeDisabled()}
                  />
                </div>
              }
              overlayData={
                <ToolTipText className="tooltip-text">
                  One or more Elements/Components are not in ‘Available for Use’
                  status
                </ToolTipText>
              }
              trigger={["hover", "focus"]}
              placement="auto"
              classId="tooltip"
            />
          ) : (
            <PrimaryButton
              title="PUBLISH"
              handleClick={() => {
                // if (isBussinessRulesView) {
                //   publishBusinessRules();
                //   return;
                // }
                publishModule(module.id);
              }}
              minWidth={110}
              marginLeft={20}
              disabled={shouldFooterPublishBeDisabled()}
            />
          )}
        </div>
      </div>
      {moduleIdToBeCloned ? (
        <CloneModule
          sourceModuleId={moduleIdToBeCloned}
          onClose={() => {
            setModuleIdToBeCloned(undefined);
          }}
        />
      ) : null}
    </>
  );
}

export default ModulePreview;
