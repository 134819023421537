import DropDownWithLabel from "components/DropDownWithLabel";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomTextField from "components/CustomTextField";
import { useTranslation } from "react-i18next";
import {
  fetchBrands,
  fetchMarkets,
  fetchTopicsAndSubtopics,
} from "services/apis";
import { getBrandsFilterData } from "dashboard/filters/BrandsFilter";
import { getMarketFilterData } from "dashboard/filters/MarketsFilter";
import { getTopicsFilterData } from "dashboard/filters/TopicsFilter";
import DropDownWithSearch from "components/DropDownWithSearch";
import MultiSelectDropDownWithInlineSearch from "components/MultiSelectDropDownWithInlineSearch";
import {
  getIndicationData,
  getLanguageData,
  getAudienceData,
  getTheurapiticData,
  getSegmentData,
  getStrategiData,
  getDraftElementsIncludedData,
} from "Uitls/Util";

const RightPane = ({
  metadata,
  updateMetadata,
  module,
  editMetaDataFlag,
  setMetadata,
  metaDataDropDownList,
  checkForEditMetadata,
  languageForCOuntry,
  subTopics,
  moduleType,
  segmentList,
  draftElementsList,
}) => {
  const { t } = useTranslation();
  const [brands, setBrands] = useState(undefined);
  const [markets, setMarkets] = useState(undefined);
  const [topicsSubtopics, setTopicsSubtopics] = useState(undefined);
  const charLimitCommunicationObj = 500;
  const charLimitModuleName = 100;
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetchBrands((res, err, errCode) => {
      if (res) {
        setBrands(res.data);
        return;
      }
    });
    fetchMarkets((res, err, errCode) => {
      if (res) {
        setMarkets(res.data);
        return;
      }
    });
    fetchTopicsAndSubtopics((res, err, errCode) => {
      if (res) {
        setTopicsSubtopics(res.data);
        return;
      }
    });
  };
  const getSubtopics = () => {
    // const topic = topicsSubtopics?.find((topic) => {
    //   return metadata?.topic?.id === topic.id;
    // });
    if (subTopics.length > 0) {
      return subTopics.map((subtopic, index) => {
        return {
          id: index,
          displayName: subtopic.subtopicName,
          veevaId: subtopic.subtopicDamId,
        };
      });
    }
    return [];
  };

  const getSubtopicsToShow = () => {
    const defaultSubtopic = { id: 0, displayName: "No Sub-Topic Available" };
    if (metadata?.topic && metadata?.brand) {
      const subtopics = getSubtopics();
      if (subtopics?.length === 0) {
        return [defaultSubtopic];
      }
      return subtopics;
    } else if (
      (metadata?.topic && !metadata?.brand) ||
      (!metadata?.topic && metadata?.brand)
    ) {
      return [defaultSubtopic];
    }
    return [];
  };

  useEffect(() => {
    if (editMetaDataFlag && editMetaDataFlag) {
      let selectedBrand = undefined;
      let selectedMarket = metadata?.country; //undefined;
      let selectedTopic = metadata?.topic; //undefined;
      let subTopicSelected = undefined;

      let audienceSelected = metadata?.audience; //undefined;
      let theraupaticAreaSelected = metadata?.theraupaticArea; //undefined;
      let selectedStrategicObjective = metadata?.strategicobj; //undefined;
      let selectedIndication = metadata?.indication; //undefined;
      let selectedSegment = metadata?.segment; //undefined;
      if (brands?.length > 0) {
        selectedBrand = getBrandsFilterData(brands).find((item) => {
          return item.veevaId === module.productDamId;
        });
      }
      if (markets?.length > 0) {
        if (!metadata.country && module?.countryDamId) {
          selectedMarket = getMarketFilterData(markets).find((item) => {
            return item.veevaId === module.countryDamId;
          });
        }
      }
      if (topicsSubtopics?.length > 0) {
        if (!metadata.topic && module.topicDamId) {
          selectedTopic = getTopicsFilterData(topicsSubtopics).find((item) => {
            return item.veevaId === module.topicDamId;
          });
        }
        // const topicsObject = topicsSubtopics?.find((topic) => {
        //   return selectedTopic?.veevaId === topic.topicDamId;
        // });
      }
      if (subTopics.length > 0) {
        let subTopicArray = subTopics?.map((subtopic, index) => {
          return {
            id: index,
            displayName: subtopic.subtopicName,
            veevaId: subtopic.subtopicDamId,
          };
        });
        subTopicSelected = subTopicArray?.find((item) => {
          return item?.veevaId === module?.subtopicDamId;
        });
      }
      if (metaDataDropDownList?.audienceNode?.length > 0) {
        if (!metadata?.audience && module.audienceDamId) {
          audienceSelected = getAudienceData(
            metaDataDropDownList?.audienceNode
          ).find((item) => {
            return item.veevaId === module.audienceDamId;
          });
        }
      }
      if (metaDataDropDownList?.theraputicAreaNode?.length > 0) {
        if (!metadata?.theraupaticArea && module?.therapeuticAreaDamId) {
          theraupaticAreaSelected = getTheurapiticData(
            metaDataDropDownList?.theraputicAreaNode
          ).find((item) => {
            return item.veevaId === module?.therapeuticAreaDamId;
          });
        }
      }
      if (metaDataDropDownList?.strategicObjectiveNode.length > 0) {
        if (!metadata?.strategicobj) {
          selectedStrategicObjective = getStrategiData(
            metaDataDropDownList?.strategicObjectiveNode
          ).find((item) => {
            return (
              item.veevaId === module?.strategicObjectives[0]?.strategicObjDamId
            );
          });
        }
      }
      if (metaDataDropDownList?.indicationNode.length > 0) {
        if (!metadata?.indication) {
          selectedIndication = getIndicationData(
            metaDataDropDownList?.indicationNode
          ).filter((item) =>
            module?.indications?.some(
              (elem) => elem.indicationDamId === item.veevaId
            )
          );
        }
      }
      // if (segmentList.length > 0) {
      //   if (!metadata?.segment) {
      //     selectedSegment = getSegmentData(segmentList).filter((item) =>
      //       module?.segments?.some((elem) => elem.segmentDamId === item.veevaId)
      //     );
      //   }
      // }
      // if (metaDataDropDownList?.segmentNode.length > 0) {
      //   selectedSegment = getSegmentData(
      //     metaDataDropDownList?.segmentNode
      //   ).filter((item) =>
      //     module?.segments?.some((elem) => elem.segmentDamId === item.veevaId)
      //   );
      // }

      setMetadata((prevState) => {
        return {
          ...prevState,
          styleGuidelines:
            metadata?.styleGuidelines || module?.brandStyleGuidelines,
          brand: selectedBrand,
          country: selectedMarket,
          topic: selectedTopic,
          subtopic: subTopicSelected,
          moduleName: metadata?.moduleName || module?.moduleName,
          communicationObjectives:
            metadata?.communicationObjectives ||
            module?.communicationObjectives,
          audience: audienceSelected,
          theraupaticArea: theraupaticAreaSelected,
          strategicobj: selectedStrategicObjective,
          indication: selectedIndication,
          //segment: selectedSegment,
        };
      });
    }
  }, [
    editMetaDataFlag,
    module,
    brands,
    markets,
    topicsSubtopics,
    metaDataDropDownList,
    subTopics,
  ]);
  useEffect(() => {
    if (
      editMetaDataFlag &&
      editMetaDataFlag &&
      metadata?.country?.veevaId === module?.countryDamId
    ) {
      let languageSelected = undefined;
      if (languageForCOuntry?.length > 0) {
        languageSelected = getLanguageData(languageForCOuntry).find((item) => {
          return item.veevaId === module.languageDamId;
        });
      }
      setMetadata((prevState) => {
        return {
          ...prevState,
          language: languageSelected,
        };
      });
    }
  }, [languageForCOuntry]);
  useEffect(() => {
    if (
      editMetaDataFlag &&
      editMetaDataFlag &&
      metadata?.country?.veevaId === module?.countryDamId
    ) {
      let selectedSegment = metadata?.segment;
      if (segmentList.length > 0) {
        if (!metadata?.segment) {
          selectedSegment = getSegmentData(segmentList).filter((item) =>
            module?.segments?.some((elem) => elem.segmentDamId === item.veevaId)
          );
        }
      }
      setMetadata((prevState) => {
        return {
          ...prevState,
          segment: selectedSegment,
        };
      });
    }
  }, [segmentList]);
  useEffect(() => {
    if (
      editMetaDataFlag &&
      editMetaDataFlag &&
      metadata?.country?.veevaId === module?.countryDamId
    ) {
      let selectedDraftElements = metadata?.draftElementIncluded;
      if (draftElementsList.length > 0) {
        if (!metadata?.segment) {
          selectedDraftElements = getDraftElementsIncludedData(
            draftElementsList
          ).filter((item) =>
            module?.moduleDraftElementIncluded?.some(
              (elem) => elem.draftElementIncludedDamId === item.veevaId
            )
          );
        }
      }
      setMetadata((prevState) => {
        return {
          ...prevState,
          draftElementIncluded: selectedDraftElements,
        };
      });
    }
  }, [draftElementsList]);

  const getSubTopicDefaultValue = () => {
    // if (metadata.topic) {
    //   return getSubtopics()?.length > 0
    //     ? metadata?.subtopic
    //     : { id: 0, displayName: "No Sub-Topic Available" };
    // }
    // return undefined;
    const defaultSubtopic = { id: 0, displayName: "No Sub-Topic Available" };
    if (metadata?.topic && metadata?.brand) {
      const subtopics = getSubtopics();
      if (subtopics?.length === 0) {
        return defaultSubtopic;
      }
      return metadata?.subtopic;
    } else if (
      (metadata?.topic && !metadata?.brand) ||
      (!metadata?.topic && metadata?.brand)
    ) {
      return defaultSubtopic;
    }

    return undefined;
  };

  const shouldSubtopicBedisabled = () => {
    if (checkForEditMetadata() && editMetaDataFlag) {
      return true;
    } else if (!checkForEditMetadata() && editMetaDataFlag) {
      return getSubtopics()?.length === 0;
    } else if (metadata?.topic && metadata?.brand) {
      return getSubtopics()?.length === 0;
    } else if (metadata?.topic && !metadata?.brand) {
      return true;
    } else if (!metadata?.topic && metadata?.brand) {
      return true;
    }
    return false;
  };
  return (
    <MetadataContainer className="row CustomScrollBar">
      <div className="col-md-6 pl-0" style={{ paddingRight: "10px" }}>
        <CustomTextField
          label={t("metadata.moduleName")}
          normalinput={true}
          handleChange={(moduleName) => {
            if (moduleName.trim().length > 0) {
              updateMetadata(moduleName, "moduleName");
            } else {
              updateMetadata("", "moduleName");
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (!metadata?.moduleName) {
                updateMetadata("", "moduleName");
              }
            }
          }}
          value={metadata?.moduleName}
          disabled={
            moduleType === "cloned"
              ? false
              : checkForEditMetadata() && editMetaDataFlag
          }
          required={true}
          charLimit={charLimitModuleName}
        />
        <DropDownWithSearch
          class="drp-dwn-Submission-doc"
          options={{
            value: metadata?.country,
            name: t("metadata.market"),
            label: t("metadata.market"),
            items: getMarketFilterData(markets),
            placeholder: "Search and Select",
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "market");
            },
            required: true,
          }}
        />
        <DropDownWithSearch
          options={{
            value: metadata?.brand,
            items: getBrandsFilterData(brands),
            name: t("metadata.brand"),
            label: t("metadata.brand"),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            disabled: editMetaDataFlag && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "brand");
            },
            required: true,
          }}
        />
        <MultiSelectDropDownWithInlineSearch
          options={{
            value: metadata?.indication,
            items: getIndicationData(metaDataDropDownList?.indicationNode),
            name: t("metadata.indication"),
            label: t("metadata.indication"),
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "indication");
            },
            //required: true,
          }}
        />
        <DropDownWithSearch
          options={{
            value: metadata?.language,
            items: getLanguageData(languageForCOuntry),
            name: t("metadata.language"),
            label: t("metadata.language"),
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "language");
            },
            required: true,
          }}
        />
        <DropDownWithSearch
          options={{
            value: metadata?.topic,
            name: t("metadata.topic"),
            label: t("metadata.topic"),
            items: getTopicsFilterData(topicsSubtopics),
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            placeholder: "Search and Select",
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "topic");
            },
            required: true,
          }}
        />
        <DropDownWithSearch
          options={{
            value: getSubTopicDefaultValue(),
            name: t("metadata.subtopic"),
            label: t("metadata.subtopic"),
            items: getSubtopicsToShow(),
            isDataTypeObject: true,
            placeholder: "Search and Select",
            disabled: shouldSubtopicBedisabled(),
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "subtopic");
            },
            //required: true,
          }}
        />
        <DropDownWithSearch
          options={{
            value: metadata?.audience,
            items: getAudienceData(metaDataDropDownList?.audienceNode),
            name: t("metadata.audience"),
            label: t("metadata.audience"),
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "audience");
            },
            placeholder: "Search and Select",
            required: true,
          }}
        />
      </div>
      <div className="col-md-6 pr-0" style={{ paddingLeft: "10px" }}>
        <DropDownWithSearch
          options={{
            value: metadata?.theraupaticArea,
            items: getTheurapiticData(metaDataDropDownList?.theraputicAreaNode),
            name: t("metadata.theraupaticArea"),
            label: t("metadata.theraupaticArea"),
            disabled: checkForEditMetadata() && editMetaDataFlag,
            isDataTypeObject: true,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "theraupaticArea");
            },
            placeholder: "Search and Select",
            required: true,
          }}
        />
        <MultiSelectDropDownWithInlineSearch
          options={{
            value: metadata?.segment,
            items: getSegmentData(segmentList),
            name: t("metadata.segment"),
            label: t("metadata.segment"),
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "segment");
            },
            placeholder: "Search and Select",
            required: true,
          }}
        />
        <DropDownWithSearch
          options={{
            value: metadata?.strategicobj,
            items: getStrategiData(
              metaDataDropDownList?.strategicObjectiveNode
            ),
            name: t("metadata.strategicobj"),
            label: t("metadata.strategicobj"),
            isDataTypeObject: true,
            disabled: checkForEditMetadata() && editMetaDataFlag,
            handleChange: (selectedValue) => {
              updateMetadata(selectedValue, "strategicobj");
            },
          }}
        />

        <CustomTextField
          label={t("metadata.communicationObj")}
          rows={7}
          rowsMax={7}
          multiline={true}
          handleChange={(description) => {
            if (description.trim().length > 0) {
              updateMetadata(description, "communicationObjectives");
            } else {
              updateMetadata("", "communicationObjectives");
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              if (!metadata?.communicationObjectives) {
                updateMetadata("", "communicationObjectives");
              }
            }
          }}
          value={metadata?.communicationObjectives}
          disabled={checkForEditMetadata() && editMetaDataFlag}
          required={true}
          charLimit={charLimitCommunicationObj}
        />

        <CustomTextField
          label={t("metadata.brandStyleGuidelinesLink")}
          link={true}
          handleChange={(styleguidelineurl) => {
            updateMetadata(styleguidelineurl, "styleGuidelines");
          }}
          value={metadata?.styleGuidelines}
          disabled={checkForEditMetadata() && editMetaDataFlag}
        />
        {metadata?.country?.veevaId === "unitedStates" && (
          <MultiSelectDropDownWithInlineSearch
            metadata={metadata}
            options={{
              value: metadata?.draftElementIncluded,
              items: getDraftElementsIncludedData(draftElementsList),
              name: t("metadata.draftElement"),
              label: t("metadata.draftElement"),
              isDataTypeObject: true,
              disabled:
                moduleType === "cloned"
                  ? false
                  : checkForEditMetadata() && editMetaDataFlag,
              handleChange: (selectedValue) => {
                updateMetadata(selectedValue, "draftElementIncluded");
              },
              placeholder: "Search and Select",
              required: true,
            }}
          />
        )}
      </div>
    </MetadataContainer>
  );
};

const MetadataContainer = styled.div`
  margin: 0px;
  height: 500px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  padding: 0px 20px;
`;

export default RightPane;
